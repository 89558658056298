<template>
  <div :id="`${sId}Container`" class="s-container">
    <!-- table -->
    <section :id="`${sId}Wrapper`" class="s-wrapper">
      <table :id="`${sId}Table`" class="s">
        <!-- header -->
        <thead class="s-head">
          <tr class="s-head-row">
            <th
              v-for="(header, headerIndex) in proxy.headers"
              :key="`s-head-row-item-${headerIndex}`"
              :width="header.width"
              :class="`s-head-row-item ${header.sort ? 'cursor-pointer' : ''}`"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <!-- /header -->

        <!-- body -->
        <tbody class="s-body">
          <!-- use row & row item -->
          <slot />
        </tbody>
        <!-- /body -->
      </table>
      <div v-if="isShowNoResultsView && !dataCount" class="text-center mt-10">
        <div>
          <i class="fas fa-magnifying-glass text-2xl text-gray-600"></i>
        </div>
        <p class="text-gray-500 text-sm">No Matching Results</p>
      </div>
    </section>
    <!-- /table -->
  </div>
</template>

<script>
export default {
  name: 'STable',
  props: {
    // also used as the export name (camelCase converted to snake_case)
    sId: {
      type: [String, Number],
      required: true,
    },

    headers: {
      type: Array,
    },
    dataCount: {
      type: Number,
      default: 0,
    },
    isShowNoResultsView: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({
    // props proxy
    proxy: {
      headers: [],
    },
  }),
  computed: {},
  async created() {},
  mounted() {},
  watch: {
    headers: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.proxy.headers = this.getMappedHeaders(val)
        }
      },
    },
  },
  methods: {
    getMappedHeaders(data) {
      return data.map((x) => {
        // todo: check for optional width
        // set default sort order
        // order null => not sorted yet || asc or desc => sorted -> show icon
        return { text: x.text, sort: x.sort, width: x.width, order: null }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './$s-table.scss';
</style>
